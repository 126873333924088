import React from "react"
import { Link, graphql } from "gatsby"
import Seo from "../components/seo";
import BaseLayout from "../components/layout"
import HorizontalRule from "../components/horizontal-rule";
import BreadcrumbTrail from "../components/breadcrumb-trail";
import kebabCase from "lodash/kebabCase";


export default class LinkTreePage extends React.Component  {
  render() {
    let breadcrumbList = [
      [
        {
          title: 'Home',
          url: '/'
        },
        {
          title: 'Link Tree',
        }
      ]
    ]
    const wallyCollection = this.props.data.wallyCollection.nodes[0]
    const talesOfTwilight = this.props.data.talesOfTwilight.nodes[0]

    return (
      <BaseLayout>
        <Seo title="Link Tree" desc="Link Tree for Zack Thoutt." pathname={"/link-tree/"} breadcrumbList={breadcrumbList}></Seo>
        <div className="layout-base-w app-p-base">
          <BreadcrumbTrail breadcrumbTrail={breadcrumbList[0]}></BreadcrumbTrail>
          <div className="text-mint">
            <HorizontalRule ruleType="1"></HorizontalRule>
            <h1 className="text-header-1 w-full">Link Tree</h1>
          </div>

          <div className="mt-20">
            <p className="text-body-2 mb-12"><Link to={`/newsletter/the-storied-scrolls/`} className="text-link">Get a free book!</Link></p>
            <p className="text-body-2 mb-12"><a href={wallyCollection.purchase.url} className="text-link" target="_blank">Book: <i>{wallyCollection.title}</i></a></p>
            <p className="text-body-2 mb-12"><a href={talesOfTwilight.purchase.url} className="text-link" target="_blank">Book: <i>{talesOfTwilight.title}</i></a></p>
            <p className="text-body-2 mb-12"><Link to={`/newsletter/the-storied-scrolls/`} className="text-link">Newsletter and Reader Group: <i>The Storied Scrolls</i></Link></p>
            <p className="text-body-2 mb-12"><Link to={`/writing/library/`} className="text-link">List of all Books</Link></p>
          </div>

        </div>
      </BaseLayout>
    )
  }
}


export const pageQuery = graphql`
  query LinkTree {
    site {
      siteMetadata {
        title
      }
    }

    wallyCollection: allCollections(
      filter: { title: { eq: "Wally and the Holiday Jailbreak" } }
      limit: 1
    ) {
      nodes {
        ...CollectionFields
      }
    }
    
    talesOfTwilight: allCollections(
      filter: { title: { eq: "Tales of Twilight" } }
      limit: 1
    ) {
      nodes {
        ...CollectionFields
      }
    }
  }
`